'use strict';

var _interopRequireDefault = require('babel-runtime/helpers/interop-require-default')['default'];

exports.__esModule = true;

var _reactPropTypesLibCommon = require('react-prop-types/lib/common');

var _childrenToArray = require('./childrenToArray');

var _childrenToArray2 = _interopRequireDefault(_childrenToArray);

exports['default'] = {

  requiredRoles: function requiredRoles() {
    for (var _len = arguments.length, roles = Array(_len), _key = 0; _key < _len; _key++) {
      roles[_key] = arguments[_key];
    }

    return _reactPropTypesLibCommon.createChainableTypeChecker(function requiredRolesValidator(props, propName, component) {
      var missing = undefined;
      var children = _childrenToArray2['default'](props.children);

      var inRole = function inRole(role, child) {
        return role === child.props.bsRole;
      };

      roles.every(function (role) {
        if (!children.some(function (child) {
          return inRole(role, child);
        })) {
          missing = role;
          return false;
        }
        return true;
      });

      if (missing) {
        return new Error('(children) ' + component + ' - Missing a required child with bsRole: ' + missing + '. ' + (component + ' must have at least one child of each of the following bsRoles: ' + roles.join(', ')));
      }
    });
  },

  exclusiveRoles: function exclusiveRoles() {
    for (var _len2 = arguments.length, roles = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      roles[_key2] = arguments[_key2];
    }

    return _reactPropTypesLibCommon.createChainableTypeChecker(function exclusiveRolesValidator(props, propName, component) {
      var children = _childrenToArray2['default'](props.children);
      var duplicate = undefined;

      roles.every(function (role) {
        var childrenWithRole = children.filter(function (child) {
          return child.props.bsRole === role;
        });

        if (childrenWithRole.length > 1) {
          duplicate = role;
          return false;
        }
        return true;
      });

      if (duplicate) {
        return new Error('(children) ' + component + ' - Duplicate children detected of bsRole: ' + duplicate + '. ' + ('Only one child each allowed with the following bsRoles: ' + roles.join(', ')));
      }
    });
  }
};
module.exports = exports['default'];